import {
	getLadditionAchatUrl,
	getReservationUrl,
} from './service/partnersConfig/saga';

// SETTINGS
import CommandeEnLigne from './Containers/CommandeEnLigne';
import Deliveroo from './Containers/Deliveroo';
import Easilys from './Containers/Easilys';
import UberEats from './Containers/UberEats';
import BFast from './Containers/BFast';
import Deliverect from './Containers/Deliverect';
import Otter from './Containers/Otter';
import LadditionTicket from './Containers/LadditionTicket';
import Tabesto from './Containers/Tabesto';
import Dood from './Containers/Dood';
import Barratio from './Containers/Barratio';
import MisterBooking from './Containers/MisterBooking';
import Mews from './Containers/Mews';
import Obypay from './Containers/Obypay';
import Sunday from './Containers/Sunday';
import Rushour from './Containers/Rushour';
import TapToPay from './Containers/LadditionTapToPay';
import Dishop from './Containers/Dishop';

// ONBOARDINGS
import ZenchefConfig from '../../Components/PartnerCard/CardConfig/Partners/Zenchef';
import GuestOnlineConfig from '../../Components/PartnerCard/CardConfig/Partners/GuestOnline';
import NetRestoConfig from '../../Components/PartnerCard/CardConfig/Partners/NetResto';
import LaFourchetteConfig from '../../Components/PartnerCard/CardConfig/Partners/LaFourchette';
import SnapshiftConfig from '../../Components/PartnerCard/CardConfig/Partners/Snapshift';
import UberEatsConfig from '../../Components/PartnerCard/CardConfig/Partners/UberEats';
import CommandeEnLigneConfig from '../../Components/PartnerCard/CardConfig/Partners/CommandeEnLigne';
import LadditionTicketConfig from '../../Components/PartnerCard/CardConfig/Partners/LadditionTicket';
import PennylaneConfig from '../../Components/PartnerCard/CardConfig/Partners/Pennylane';
import ComoConfig from '../../Components/PartnerCard/CardConfig/Partners/Como';
import DoodConfig from '../../Components/PartnerCard/CardConfig/Partners/Dood';
import DeliverectConfig from '../../Components/PartnerCard/CardConfig/Partners/Deliverect';
import TabestoConfig from '../../Components/PartnerCard/CardConfig/Partners/Tabesto';
import KoustConfig from '../../Components/PartnerCard/CardConfig/Partners/Koust';
import BarratioConfig from '../../Components/PartnerCard/CardConfig/Partners/Barratio';
import InpulseConfig from '../../Components/PartnerCard/CardConfig/Partners/Inpulse';
import LadditionMenuConfig from '../../Components/PartnerCard/CardConfig/Partners/LadditionMenu';
import MisterBookingConfig from '../../Components/PartnerCard/CardConfig/Partners/Misterbooking';
import EviivoConfig from '../../Components/PartnerCard/CardConfig/Partners/Eviivo';
import MewsConfig from '../../Components/PartnerCard/CardConfig/Partners/Mews';
import YokitupConfig from '../../Components/PartnerCard/CardConfig/Partners/Yokitup';
import SkelloConfig from '../../Components/PartnerCard/CardConfig/Partners/Skello';
import PongoConfig from '../../Components/PartnerCard/CardConfig/Partners/Pongo';
import TickncookConfig from '../../Components/PartnerCard/CardConfig/Partners/Tickncook';
import SundayConfig from '../../Components/PartnerCard/CardConfig/Partners/Sunday';
import ObypayConfig from '../../Components/PartnerCard/CardConfig/Partners/Obypay';
import ZerosixConfig from '../../Components/PartnerCard/CardConfig/Partners/Zerosix';
import DeliverooConfig from '../../Components/PartnerCard/CardConfig/Partners/Deliveroo';
import TapToPayConfig from '../../Components/PartnerCard/CardConfig/Partners/TapToPay';
import DishopConfig from '../../Components/PartnerCard/CardConfig/Partners/Dishop';
import SommitConfig from '../../Components/PartnerCard/CardConfig/Partners/Sommit';
import MelbaConfig from '../../Components/PartnerCard/CardConfig/Partners/Melba';
import RushourConfig from '../../Components/PartnerCard/CardConfig/Partners/Rushour';
import FoodReportConfig from '../../Components/PartnerCard/CardConfig/Partners/FoodReport';
import DionysolsConfig from '../../Components/PartnerCard/CardConfig/Partners/Dionysols';
import ChiftConfig from '../../Components/PartnerCard/CardConfig/Partners/Chift';
import StansoftConfig from '../../Components/PartnerCard/CardConfig/Partners/Stansoft';
import KoliaConfig from '../../Components/PartnerCard/CardConfig/Partners/Kolia';
import CoverManager from '../../Components/PartnerCard/CardConfig/Partners/CoverManager';

// ONBOARDINGS
export const prefixToMachine = {
	zen: ZenchefConfig,
	guest: GuestOnlineConfig,
	netresto: NetRestoConfig,
	fourch: LaFourchetteConfig,
	snapshift: SnapshiftConfig,
	ubereats: UberEatsConfig,
	celladdition: CommandeEnLigneConfig,
	ladditionTicket: LadditionTicketConfig,
	como: ComoConfig,
	pennylane: PennylaneConfig,
	deliverect: DeliverectConfig,
	dood: DoodConfig,
	tabesto: TabestoConfig,
	koust: KoustConfig,
	barratio: BarratioConfig,
	deepsight: InpulseConfig,
	ladditionMenu: LadditionMenuConfig,
	misterBooking: MisterBookingConfig,
	eviivo: EviivoConfig,
	mewsSystems: MewsConfig,
	yokitup: YokitupConfig,
	skello: SkelloConfig,
	pongo: PongoConfig,
	tickncook: TickncookConfig,
	sunday: SundayConfig,
	obypay: ObypayConfig,
	zerosix: ZerosixConfig,
	deliveroo: DeliverooConfig,
	ladditionTapToPay: TapToPayConfig,
	dishop: DishopConfig,
	chaponfin2: SommitConfig,
	melba: MelbaConfig,
	rushour: RushourConfig,
	theometrics: FoodReportConfig,
	1055: DionysolsConfig,
	chift: ChiftConfig,
	stansoft: StansoftConfig,
	kolia: KoliaConfig,
	covermanager: CoverManager,
};

export const EXPORT_TYPE = {
	SECTION_WITH_EXTRAS: 'SECTION_WITH_EXTRAS',
	SECTION_WITHOUT_EXTRAS: 'SECTION_WITHOUT_EXTRAS',
	UNIQUE_WITH_EXTRAS: 'UNIQUE_WITH_EXTRAS',
	UNIQUE_WITHOUT_EXTRAS: 'UNIQUE_WITHOUT_EXTRAS',
};

// PAGE DE REGLAGES
export let PARTNERS = {
	celladdition: {
		name: 'CommandeEnLigne',
		component: CommandeEnLigne,
		type: EXPORT_TYPE.SECTION_WITH_EXTRAS,
	},
	deliverect: {
		name: 'Deliverect',
		component: Deliverect,
		type: EXPORT_TYPE.SECTION_WITH_EXTRAS,
	},
	deliveroo: {
		name: 'Deliveroo',
		component: Deliveroo,
		type: EXPORT_TYPE.SECTION_WITH_EXTRAS,
	},
	ubereats: {
		name: 'UberEats',
		component: UberEats,
		type: EXPORT_TYPE.SECTION_WITH_EXTRAS,
	},
	easilys: {
		name: 'Easilys',
		component: Easilys,
		type: EXPORT_TYPE.UNIQUE_WITHOUT_EXTRAS,
	},
	bfast: {
		name: 'BFast',
		component: BFast,
		type: EXPORT_TYPE.SECTION_WITH_EXTRAS,
	},
	como: {
		name: 'Como',
	},
	otter: {
		name: 'Otter',
		component: Otter,
		type: EXPORT_TYPE.SECTION_WITH_EXTRAS,
	},
	pennylane: {
		name: 'Pennylane',
	},
	netresto: {
		name: 'Mycawan',
	},
	fourch: {
		name: 'LaFourchette',
	},
	guest: {
		name: 'Guestonline',
	},
	snapshift: {
		name: 'Combo',
	},
	zen: {
		name: 'Zenchef',
	},
	ladditionTicket: {
		name: 'LadditionTicket',
		component: LadditionTicket,
	},
	tabesto: {
		name: 'Tabesto',
		component: Tabesto,
		type: EXPORT_TYPE.SECTION_WITH_EXTRAS,
	},
	dood: {
		name: 'Dood',
		component: Dood,
	},
	barratio: {
		name: 'Barratio',
		component: Barratio,
	},
	misterBooking: {
		name: 'MisterBooking',
		component: MisterBooking,
	},
	ladditionMenu: {
		name: 'ladditionMenu',
		external_link: getLadditionMenuUrl(),
	},
	koust: {
		name: 'koust',
	},
	deepsight: {
		name: 'inpulse',
	},
	eviivo: {
		name: 'eviivo',
	},
	ouilink: {
		name: 'ouilink',
		external_link: getLadditionAchatUrl(),
	},
	laddition: {
		name: 'laddition',
		external_link: getReservationUrl(),
	},
	mewsSystems: {
		name: 'MewsSystems',
		component: Mews,
	},
	yokitup: {
		name: 'Yokitup',
	},
	obypay: {
		name: 'Obypay',
		component: Obypay,
	},
	skello: {
		name: 'Skello',
	},
	tickncook: {
		name: 'Tickncook',
	},
	pongo: {
		name: 'Pongo',
	},
	sunday: {
		name: 'Sunday',
		component: Sunday,
	},
	zerosix: {
		name: 'Zerosix',
	},
	rushour: {
		name: 'Rushour',
		component: Rushour,
		type: EXPORT_TYPE.SECTION_WITH_EXTRAS,
	},
	ladditionTapToPay: {
		name: "l'Addition Tap-to-Pay",
		component: TapToPay,
	},
	dishop: {
		name: 'Dishop',
		component: Dishop,
	},
	chaponfin2: {
		name: 'Sommit',
	},
	melba: { name: 'Melba' },
	theometrics: { name: 'Food Report' },
	1055: { name: 'Dionysols' },
	chift: { name: 'Chift' },
	stansoft: { name: 'GoStan' },
	kolia: { name: 'Kolia' },
	covermanager: { name: 'Cover Manager' },
};

for (let prefix in PARTNERS) {
	if (prefixToMachine[prefix]) {
		PARTNERS[prefix].onboarding = prefixToMachine[prefix];
	}
}

function getLadditionMenuUrl() {
	if (window.location && window.location.hostname === 'localhost') {
		return 'http://localhost:4001';
	} else if (
		window.location &&
		window.location.hostname.includes('laddit.io')
	) {
		return 'http://menu.laddit.io';
	} else {
		return 'http://menu.laddition.com';
	}
}
